import { useController } from 'react-hook-form';
import './index.css'
import { useEffect, useRef } from 'react';

export const BaseCheckbox = ({ name, control, label, isRequired = true, ...rest }) => {
    const inputRef = useRef(null)

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue: rest.defaultValue || '',
    });

    useEffect(() => {
        if (!value) {
            if (inputRef.current) {
                inputRef.current.checked = false;
            }
        }
    }, [value])

    return <div className="checkbox-wrapper">
        <input
            {...rest}
            name={name}
            ref={(e) => {
                ref(e)
                inputRef.current = e
            }}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            type='checkbox'
        />
        {label}
    </div>
}