import './index.css'
import logo from '../../resources/img/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { PATH } from '../../consts/path'
import { useEffect, useRef, useState } from 'react'
import menuSvg from '../../resources/svg/menu.svg'
import closeIcon from '../../resources/svg/close.png'
const MENUS = [
    {
        title: 'Home',
        key: 'home',
        path: PATH.HOME
    },
    {
        title: 'About',
        key: 'about',
        path: PATH.ABOUT
    },
    {
        title: 'News',
        key: 'news',
        path: PATH.NEWS
    },
    {
        title: 'Brand',
        key: 'brand',
        path: PATH.BRAND
    },
    {
        title: 'Media',
        key: 'media',
        path: PATH.MEDIA
    },
    {
        title: 'SDGs',
        key: 'sdgs',
        path: PATH.SDGS
    },
    {
        title: 'Contact',
        key: 'contact',
        path: PATH.CONTRACT
    },
    {
        title: 'Company',
        key: 'company',
        path: PATH.COMPANY
    }
]


export const Header = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false)
    const observerRef = useRef(null);


    const handleChangeDocumentTitle = () => {
        const path = location.pathname
        const currentTitle = MENUS.find((menu) => menu.path === path)?.title || 'CHARION'
        document.title = `${currentTitle} | CHARION`
    }

    useEffect(() => {

        handleChangeDocumentTitle()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [location])


    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (!entry.target.classList.contains("animate_custom")) {
                            entry.target.classList.add(
                                "animate__animated",
                                "animate__fadeInUp"
                            );
                            entry.target.style.opacity = 1;
                        }
                        observerRef.current.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0,
            }
        );

        const elements = document.querySelectorAll(".animate__animated");
        elements.forEach((element) => {
            observerRef.current.observe(element);
        });

        return () => {
            observerRef.current.disconnect();
        };
    }, [location]);

    return <div className='wrapper-container border-header '>
        <header className="header-wrapper container">
            <div className='header_logo'
                onClick={() => {
                    navigate(PATH.HOME)
                }}
            >
                <img alt='logo' src={logo} />
            </div>
            <ul className='header_menu'>
                {MENUS.map((menu) => {
                    const activeClassName = location.pathname === menu.path ? 'active' : ''
                    return <li
                        className={`header_menu-item ${activeClassName}`}
                        onClick={() => {
                            navigate(menu.path)
                        }}
                    >{menu.title}</li>
                })}
            </ul>
            <div className={`header_menu-mobile`}>
                <div className='header_menu-icon' onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}>
                    <img src={isOpenMenuMobile ? closeIcon : menuSvg} alt='menu' />
                </div>
                <ul className={`${isOpenMenuMobile ? 'open' : ''}`}>
                    {MENUS.map((menu) => {
                        const activeClassName = location.pathname === menu.path ? 'active' : ''
                        return <li
                            className={`header_menu-item ${activeClassName}`}
                            onClick={() => {
                                navigate(menu.path)
                            }}
                        >{menu.title}</li>
                    })}
                </ul>
            </div>
        </header>
    </div>
}