export const MEDIA_DATA = [
    {
        imageUrl: 'https://charion.co.jp/wp-content/themes/charion_renewal/images/new_01_2.jpg',
        videoUrl: 'https://charion.co.jp/wp-content/themes/charion_renewal/images/new_1.mp4'
    },
    {
        imageUrl: 'https://i.ytimg.com/vi_webp/4qo0jaevtPo/sddefault.webp',
        videoUrl: 'https://www.youtube.com/embed/4qo0jaevtPo',

    },
    {
        imageUrl: 'https://charion.co.jp/wp-content/themes/charion_renewal/images/new_03_2.jpg',
        videoUrl: 'https://charion.co.jp/wp-content/themes/charion_renewal/images/new_3.mp4'
    },
    {
        imageUrl: 'https://charion.co.jp/wp-content/themes/charion_renewal/images/new_02_2.jpg',
        videoUrl: 'https://charion.co.jp/wp-content/themes/charion_renewal/images/new_2.mp4'
    },
]